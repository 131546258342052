"use client";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useState } from "react";
import ProfileNavigation from "./ProfileNavigation";
import { routeUrls } from "../dataMockups/constants";
import { useUser } from "@clerk/nextjs";
import { getInitials } from "../utils/GenericFunction";
import MedEngineIcon from "../../public/imgs/icons/new-icon.png";
import MedEngineIconWhite from "../../public/imgs/icons/new-icon-white.png";

const Header = () => {
	const pathname = usePathname();
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
	const { isSignedIn, isLoaded, user } = useUser();

	// Checker for URLs
	const isHomePage = pathname === "/";
	const isRegistrationFlowPage =
		pathname === "/auth/login" || pathname === "/auth/registration" || pathname === "/preference";
	const isSearchPage = pathname.startsWith("/search");
	const isFeedPage = pathname.startsWith("/feed");
	const isBookmarkPage = pathname.startsWith("/bookmarks");
	const isFolderPage = pathname.startsWith("/folders");
	// End of URL checker

	const renderNavBar = () => {
		if (isSearchPage || isFeedPage || isBookmarkPage || isFolderPage) {
			return (
				<nav className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-fit w-fit space-x-4">
					{/* Mobile: Show only Home and Feed */}
					<Link
						href="/"
						className={`lg:hidden ${isHomePage ? "text-primaryBlue" : "text-white"} ${
							isSearchPage ? "border-b-white border-b-2" : "border-b-transparent"
						}`}
					>
						Home
					</Link>
					<Link
						href="/feed/author:none/journal:none/startdate:2000/enddate:2024/searchEngine:MedEngine/Clinical%20Trial:true/Meta-Analysis:true/Randomized%20Controlled%20Trial:true/Review:true/Case%20Report:true/Primary%20Article:true/All:true/isTop50Journal:true/hasPdf:true"
						className={`lg:hidden ${isHomePage ? "text-primaryBlue" : "text-white"} ${
							isFeedPage ? "border-b-white border-b-2" : "border-b-transparent"
						}`}
					>
						Feed
					</Link>
		
					{/* Desktop: Show all links */}
					<Link
						href="/"
						className={`hidden lg:flex ${isHomePage ? "text-primaryBlue" : "text-white"} ${
							isSearchPage ? "border-b-white border-b-2" : "border-b-transparent"
						}`}
					>
						Home
					</Link>
					<Link
						href="/feed/author:none/journal:none/startdate:2000/enddate:2024/searchEngine:MedEngine/Clinical%20Trial:true/Meta-Analysis:true/Randomized%20Controlled%20Trial:true/Review:true/Case%20Report:true/Primary%20Article:true/All:true/isTop50Journal:true/hasPdf:true"
						className={`hidden lg:flex ${isHomePage ? "text-primaryBlue" : "text-white"} ${
							isFeedPage ? "border-b-white border-b-2" : "border-b-transparent"
						}`}
					>
						Feed
					</Link>
					<Link
						href="/bookmarks"
						className={`hidden lg:flex ${isHomePage ? "text-primaryBlue" : "text-white"} ${
							isBookmarkPage ? "border-b-white border-b-2" : "border-b-transparent"
						}`}
					>
						Bookmarks
					</Link>
					<Link
						href="/folders"
						className={`hidden lg:flex ${isHomePage ? "text-primaryBlue" : "text-white"} ${
							isFolderPage ? "border-b-white border-b-2" : "border-b-transparent"
						}`}
					>
						Folders
					</Link>
				</nav>
			);
		}
		
		

		if (!isRegistrationFlowPage) {
			return (
				<nav className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-fit w-fit space-x-4">
					<Link
						href="/search/query:none/author:none/journal:none/startdate:2000/enddate:2024/searchEngine:MedEngine/Clinical%20Trial:true/Meta-Analysis:true/Randomized%20Controlled%20Trial:true/Review:true/Case%20Report:true/Primary%20Article:true/All:true"
						className={`${isHomePage ? "text-primaryBlue" : "text-white"} ${
							isSearchPage ? "border-b-white border-b-2" : "border-b-transparent"
						}`}
					>
						Search
					</Link>
					<Link
						href="/feed/author:none/journal:none/startdate:2000/enddate:2024/searchEngine:MedEngine/Clinical%20Trial:true/Meta-Analysis:true/Randomized%20Controlled%20Trial:true/Review:true/Case%20Report:true/Primary%20Article:true/All:true/isTop50Journal:true/hasPdf:true"
						className={`${isHomePage ? "text-primaryBlue" : "text-white"} ${
							isFeedPage ? "border-b-white border-b-2" : "border-b-transparent"
						}`}
					>
						Feed
					</Link>
				</nav>
			);
		}

		return null;
	};

	return (
		<>
			<header
				className={`${
					isHomePage
						? "absolute w-full bg-transparent"
						: "homeGradientBg sticky top-0 z-50 p-4"
				} flex items-center justify-between p-4`}
			>
				<Link href={"/"} className="flex items-center gap-2">
					{isHomePage ? (
						<Image src={MedEngineIcon} width={35} height={35} alt="Logo" />
					) : (
						<Image src={MedEngineIconWhite} width={35} height={35} alt="Logo" />
					)}

					<span
						className={`${
							isHomePage
								? " hidden text-xl font-medium text-[#00242F] lg:block"
								: "hidden text-xl font-medium text-white lg:block"
						}`}
					>
						BioMed Engine.ai
					</span>
				</Link>

				{renderNavBar()}

				{isSignedIn ? (
					<>
						<button
							onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
							className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-400 text-white lg:hidden"
						>
							{isLoaded && getInitials(user?.fullName)}
						</button>
						<Link
							href={routeUrls.profile}
							className="hidden h-8 w-8 items-center justify-center rounded-full bg-gray-400 text-white lg:flex"
						>
							{isLoaded && getInitials(user?.fullName)}
						</Link>
					</>
				) : (
					!isRegistrationFlowPage && (
						<Link href="/auth/login">
							<button
								type="button"
								className="h-[40px] w-fit min-w-[78px] rounded border border-primaryBlack px-[16px] text-sm font-medium text-primaryBlack"
							>
								Sign In
							</button>
						</Link>
					)
				)}
			</header>
			{isMobileMenuOpen && (
				<ProfileNavigation
					setShowProfile={setMobileMenuOpen}
					showProfileNav={isMobileMenuOpen}
				/>
			)}
		</>
	);

};

export default Header;
